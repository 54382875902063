<template>

    <b-container class="py-5">
        <div class="h3 text-white">Employment history</div>

        <div v-for="(job) in history" :key="job.company" class="job">
            <div class="text-light">
                {{ job.timeline[0] }} - {{ job.timeline[1] }}
            </div>
            <div>
                <h2>
                    {{ job.role }} | {{ job.company }}
                </h2>
            </div>
            <div>
                <b-badge v-for="point in job.bulletpoints" :key="point">{{ point }}</b-badge>
            </div>
            <div class="row mt-5">
                <div class="text-start text-light">
                    <p v-for="paragraph in job.description" :key="paragraph">{{ paragraph }}</p>
                </div>
            </div>
        </div>

    </b-container>
</template>

<script>
export default {
    name: 'HomeBio',
    data() {
        return {
            slide: 0,
            sliding: null,
            history: [
                {
                    company: 'Partner banka',
                    role: 'Software Developer',
                    description: [
                        "As a software developer in the banking sector, I specialize in developing and maintaining critical systems including Customer Relationship Management (CRM), Document Management Systems (DMS), and Data Warehousing (DWH). My role also involves creating robust reporting solutions for various financial institutions and performing in-depth data analysis. By leveraging advanced technologies and methodologies, I ensure seamless integration, efficiency, and compliance within the bank’s operational framework.",
                        "Through this work, I've developed strong skills in solving and optimizing complex problems, with a focus on the quick resolution of critical issues within production environments. My experience also includes extensive data analysis on large datasets. I actively organize tasks and mentor colleagues."
                    ],
                    bulletpoints: ['Python', 'Flask', 'PostgreSQL', 'MySQL', 'Informix', 'Backbone,js'],
                    timeline: ['October 2018', 'Present']
                },
                {
                    company: 'Freelance',
                    role: 'Software Developer',
                    description: [
                        "I have successfully delivered a range of specialized web applications and platforms tailored to diverse industries. My portfolio includes a specialized app for managing work orders (CRM) in car repair shops, a platform connecting performers with event organizers, and an app for self-managing QR menus. Additionally, I have developed custom webshops for businesses such as sawmills, car part retailers, and air conditioning suppliers. My expertise lies in understanding unique business needs and translating them into efficient, user-friendly digital solutions.",
                        "I have a strong understanding of unique business needs and excel at translating requirements into user-friendly solutions. My work involves developing specialized web applications and ensuring seamless integration and an optimal user experience. Additionally, I handle server configuration and am experienced in preparing detailed proposals, as well as providing accurate time and cost estimates for projects."
                    ],
                    bulletpoints: ['Python', 'Flask', 'PostgreSQL', 'MySQL', 'Vue.js'],
                    timeline: ['July 2017', 'Present']
                },
                {
                    company: 'Webomat',
                    role: 'Computer Technitian',
                    description: ['I worked on a number of websites and webshops mainly in Joomla and Wordpress. I also did some HTML and CSS work and some PHP programming.',

                    ],
                    bulletpoints: ['Joomla', 'Wordpress', 'HTML', 'CSS', 'PHP'],
                    timeline: ['January 2016', 'January 2017']
                },

            ]
        }
    },
}
</script>

<style scoped>
h1{
    margin-top: 200px;
}
.job {
    margin: 50px 0;
    padding: 20px;
    /* background-color: #f8f9fa; */
    border-radius: 5px;
    /* color: black; */
    /* border-top: 1px solid grey; */
}

.badge{
    margin-right: 5px;
}
</style>
