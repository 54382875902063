// Import all images from the `src/assets/images` directory
const requireContext = require.context('./images', false, /\.(jpg|jpeg|png|gif|svg)$/);

// Create an object mapping image names to image URLs
const images = requireContext.keys().reduce((acc, path) => {
  const imageName = path.replace('./', '').replace(/\.(jpg|jpeg|png|gif|svg)$/, '');
  acc[imageName] = requireContext(path);
  return acc;
}, {});

export default images;