<template>
    <div class="container" id="footer-container" >
        <div class="row">
            <div class="col-5 col-md-2 pt-4 pt-md-1">
                <b-img thumbnail fluid-grow :src="logo" class="logo" alt="hero-image"></b-img>
            </div><!-- col-md-6 -->
            <div class="col col-md-9">
                <div class="text-white">Thank you for reading</div>
                <div class="h5 pt-4">August Planinšek</div>
                <div class="text-white"><i>Software Developer</i></div>

                <div class="text-white pt-4">
                    E-mail: <span>{{ fn }}</span>&commat;<span>{{ domain }}</span>
                </div>
            </div>
        </div><!-- row -->
        <div class="py-5 text-white text-center">
            © {{ year }} August Planinšek. All rights reserved.
        </div>
    </div>
</template>

<script>
import planinsekLogo from '@/assets/images/planinsek_logo.png'

export default {
    name: 'HomeFooter',
    data() {
        return {
            logo: planinsekLogo,
            year: new Date().getFullYear(),
            fn: 'august',
            domain: 'planinsek.net'
        }
    }
}
</script>

<style scoped>
#footer-container{
    border-top: 1px solid white;
    padding-top: 50px;
}
.logo{
    display: block;
    width: 70px;
    height: auto;
}

</style>
