import { createApp } from 'vue'
import App from './App.vue'

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap';

import BootstrapVue3 from 'bootstrap-vue-3';
import './assets/custom.scss';


import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { createMetaManager } from 'vue-meta';


// Add all icons to the library so you can use them in your components
library.add(fas, fab);

const app = createApp(App);

app.use(createMetaManager());
app.use(BootstrapVue3)
app.component('font-awesome-icon', FontAwesomeIcon); // Register Font Awesome component globally

app.mount('#app');