<template>
  <div id="app">
    <br>
    <HomeHero />
    <br>
    <HomeProjects />
    <br>
    <HomeBio />
    <HomeFooter />
  </div>
</template>

<script>
import HomeHero from './components/HomeHero.vue'
import HomeProjects from './components/HomeProjects.vue'
import HomeBio from './components/HomeBio.vue'
import HomeFooter from './components/HomeFooter.vue'

export default {
  name: 'App',
  metaInfo: {
    title: 'August Planinšek - Expert Python Developer',
    meta: [
      { name: 'description', content: "Hi! I'm August Planinšek. If you're looking for a website or a custom business web application, check out my projects, learn more about me, and feel free to get in touch." },
      { name: 'keywords', content: 'August Planinšek, August Planinsek, Software developer, Web developer, Zagreb, Python, Flask, Vue, PostgreSQL, MySQL, Informix' }
    ]
  },

  components: {
    HomeHero,
    HomeProjects,
    HomeBio,
    HomeFooter
  }
}
</script>

<style>
body {
  margin: 0;
  padding: 0;
  background: black !important;
}
#app {
  font-family: Calibri, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #179510;
  font-size: 1.1rem;
  text-align: left;
}
</style>
