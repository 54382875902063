<template>
    <div class="container" id="hero-container">
        <div class="row">
            <div class="col-md-9">
                <div class="py-5">
                    <div class="h5 text-light text-start">
                        Hi, I'm
                    </div>
                    <div class="">
                        <div>
                            <h1 class="text-start">August Planinšek</h1>
                            <h6 class="text-light text-start">
                                <i>
                                    Expert Python Developer for Complex Business Applications
                                    based in Zagreb, Croatia
                                </i>
                            </h6>
                        </div>
                    </div>
                </div>
            </div><!-- col-md-6 -->
            <div class="col-md-3">
                <b-img thumbnail fluid-grow :src="heroImg" class="hero-img" alt="hero-image"></b-img>
            </div><!-- col-md-6 -->
        </div><!-- row -->
        <div class="text-light text-center mt-5 py-5 h3">
            I love turning challenging requirements into user-friendly, high-performance solutions that help businesses
            thrive.
        </div>
    </div>
</template>

<script>
import heroImg from '@/assets/images/hero-photo.jpeg'
import planinsekLogo from '@/assets/images/planinsek_logo.png'

export default {
    name: 'HomeHero',
    data() {
        return {
            heroImg: heroImg,
            logo: planinsekLogo
        }
    }
}
</script>

<style scoped>
.logo{
    display: block;
    width: 70px;
    height: auto;
}


p{
    color: white

}

h3{
    margin-top: 40px;
}

h5{
    margin-top: 30px;
}

/* ul{
    font-size: 1.3em;
    margin-top: 50px;
    text-align: left;
} */

li{
    margin-bottom: 7px;

}

#technologies{
    margin-top: 50px;
    width: 200px;
    display: inline-block;
}

#hero-container{
    padding-top: 150px;
    padding-bottom: 200px;
}
.hero-img{
    width: 100%;
    height: auto;
    /* margin-top: -60px; */
}
</style>
