const jsonModules = require.context('./projects/en/', false, /\.json$/);
const jsonData = {};

const keys = jsonModules.keys();
const totalItems = keys.length;

keys.reverse().forEach((key, index) => {
  const originalIndex = totalItems - 1 - index
  // Extract the file name without extension
  const fileName = key.replace('./', '').replace('.json', '');
  // Assign the content to the jsonData object using the file name as the key
  jsonData[fileName] = jsonModules(key);
  jsonData[fileName]['index'] = originalIndex;
});

export default jsonData