<template>
    <div class="container">
        <div class="h3 text-start text-white">
            Projects
        </div>
        <div style="border-left: 5px solid #ddd; padding-left: 15px; padding-top: 30px; cursor: pointer;">
            <div
                @click="openQuoteModal"
                style="width: 100%; height: 150px; border: 2px dashed #ddd; border-radius: 10px; padding: 60px 0; background: #dadada55;" class="text-center"
            >
                <span class="text-white">Placeholder for your project.</span> Request a quote...
            </div>
            <div class="card bg-light project-card my-5" v-for="(project) in projects" :key="project.index">
                <div class="card-body">
                    <div class="row">
                        <div class="col-md-5">
                            <img :src="images['project-' + project.index]" alt="project-img" class="img img-fluid">
                        </div>
                        <div class="col-md text-start">
                            <div>
                                <div class="text-start">
                                    {{ project.year }}
                                </div>
                                <div class="text-start h4">
                                    {{ project.name }}
                                </div>
                                <div class="text-start">
                                    <a :href="project.url" v-if="project.url" target="_blank">{{ project.url }}</a>
                                </div>
                            </div>
                            <div class="pt-3">
                                <p v-for="(parag, index) in project.intro" :key="index">{{ parag }}</p>
                                <button type="button" class="btn btn-secondary" @click="openModal(project)">Read
                                    more...</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- Modal Structure -->
    <div class="modal fade" id="exampleModal" tabindex="-1" aria-hidden="true" ref="exampleModal">
        <div class="modal-dialog modal-xl">
            <div class="modal-content">
                <div class="modal-body text-black">
                    <div class="row">
                        <div class="col-md-5 order-3 order-md-1">
                            <img :src="images['project-' + mProject.index]" alt="project-img" class="img img-fluid">
                        </div>
                        <div class="col-md-6 order-2 order-md-2">
                            <div class="text-black">
                                <div class="text-start">
                                    {{ mProject.year }}
                                </div>
                                <div class="text-start h4">
                                    {{ mProject.name }}
                                </div>
                                <div class="text-start">
                                    <a :href="mProject.url" v-if="mProject.url" target="_blank">{{ mProject.url }}</a>
                                </div>
                            </div>
                            <div v-if="mProject.techStack" class="py-4">
                                <div v-if="mProject.techStack.frontend">
                                    Frontend:
                                    <b-badge v-for="t in mProject.techStack.frontend" :key="t">{{ t }}</b-badge>
                                </div>
                                <div v-if="mProject.techStack.backend">
                                    Backend:
                                    <b-badge v-for="t in mProject.techStack.backend" :key="t">{{ t }}</b-badge>
                                </div>
                                <div v-if="mProject.techStack.database">
                                    Database:
                                    <b-badge v-for="t in mProject.techStack.database" :key="t">{{ t }}</b-badge>
                                </div>
                            </div>
                        </div>
                        <div class="col order-1 order-md-3">
                            <div class="d-flex justify-content-end">
                                <button type="button" class="btn-close" @click="closeModal"></button>
                            </div>
                        </div>
                    </div>

                    <div v-if="mProject.intro" class="py-3">
                        <p v-for="p in mProject.intro" :key="p">{{ p }}</p>
                    </div>
                    <div v-if="mProject.keyFeatures">
                        <ul>
                            <li v-for="f in mProject.keyFeatures" :key="f" class="py-1">
                                <div class="">
                                    <strong>
                                        {{ f[0] }}
                                    </strong>
                                </div>
                                <div>
                                    {{ f[1] }}
                                </div>
                            </li>
                        </ul>
                    </div>
                    <div v-if="mProject.outro" class="py-3">
                        <p v-for="p in mProject.outro" :key="p">{{ p }}</p>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="modal fade" id="quoteModal" tabindex="-1" aria-hidden="true" ref="quoteModal">
        <div class="modal-dialog modal-xl">
            <div class="modal-content">
                <div class="modal-body text-black">
                    <div v-if="formSent === null">
                        <div class="h4 py-3">
                            Send me a message and request a quote...
                        </div>
                        <form action="#" method="post" id="quoteForm" @submit.prevent="sendQuoteForm">
                            <div class="row">
                                <div class="col-md">
                                    <div>
                                        <label for="name">Name</label>
                                        <input type="text" class="form-control" id="name" v-model="formData.name">
                                    </div>
                                </div>
                                <div class="col-md">
                                    <div>
                                        <label for="email">E-mail</label>
                                        <input type="email" class="form-control" id="email" v-model="formData.email">
                                    </div>
                                    <div class="confirmEmail">
                                        <label for="confirmEmail">Confirm e-mail</label>
                                        <input type="text" class="form-control" id="confirmEmail" v-model="formData.confirmEmail">
                                    </div>
                                </div>
                                <div class="col-md">
                                    <div>
                                        <label for="phone">Phone</label>
                                        <input type="text" class="form-control" id="phone" v-model="formData.phone">
                                    </div>
                                </div>
                            </div>
                            <div>
                                <label for="message">Message</label>
                                <textarea class="form-control" v-model="formData.message" id="message" placeholder="Describe the project..."></textarea>
                            </div>
                            <div class="py-3 d-flex justify-content-between">
                                <button type="button" @click="closeQuoteModal" class="btn btn-secondary">Cancel</button>
                                <button type="submit" class="btn btn-primary">Send</button>
                            </div>
                        </form>
                    </div>
                    <div v-if="formSent === true">
                        <div class="d-flex justify-content-center">
                            <img :src="images['message-sent-green']" alt="Message sent" class="img-fluid">
                        </div>
                        <div class="h4 text-center">
                            Thank you!
                        </div>
                        <div class="text-center">
                            Message has been sent. I will get back to you as soon as possible.
                        </div>
                        <div class="text-center py-3">
                            <button type="button" @click="closeQuoteModal" class="btn btn-secondary">Ok</button>
                        </div>
                    </div>
                    <div v-if="formSent === false">
                        <div class="text-center py-3">
                            <h4>There was an error. Please try again...</h4>
                        </div>
                        <div class="text-center py-3">
                            <button type="button" @click="closeQuoteModal" class="btn btn-secondary">Ok</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>



</template>

<script>
import axios from 'axios';
import bootstrap from 'bootstrap/dist/js/bootstrap.bundle.js';

import images from '@/assets/image-loader.js';
import projects from '@/assets/projects-loader.js';

export default {
    name: 'HomeProjects',
    data() {
        return {
            slide: 0,
            sliding: null,
            projects: projects,
            images: images,
            isModalOpen: false,
            modalInstance: null,
            mProject: {},
            formSent: null,
            formData: {
                name: null,
                email: null,
                confirmEmail: null,
                phone: null,
                message: null
            }
        }
    },
    mounted() {
        // Initialize the Bootstrap modal instance when the component is mounted
        this.modalInstance = new bootstrap.Modal(this.$refs.exampleModal);
        this.quoteModalInstance = new bootstrap.Modal(this.$refs.quoteModal);
    },
    methods: {
        openModal(project) {
            this.mProject = project;
            this.modalInstance.show();
        },
        closeModal() {
            this.modalInstance.hide();
        },
        openQuoteModal() {
            this.quoteModalInstance.show();
        },
        closeQuoteModal() {
            this.quoteModalInstance.hide();
        },
        async sendQuoteForm() {
            try {
                const response = await axios.post('/request-a-quote', this.formData, {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                });
                console.log(response)
                this.formSent = true;
            } catch (error) {
                console.error('Error:', error);
                this.formSent = false;
            }

        }

    }

}
</script>

<style scoped>
.confirmEmail{
    display: none;
}
.badge{
    margin-right: 5px;
    /* font-size: 1.1rem; */
}
.card-footer{
    background: none;
    border-top: none;
}
.project-card:before{
    content:" ";
    width:0px;
    background:transparent;
    height:0;
    position:absolute;
    top:20px;
    left:-30px;
    border-right: 15px solid rgba(var(--bs-light-rgb));
    border-top: 15px solid transparent;
    border-left: 15px solid transparent;
    border-bottom: 15px solid transparent;
}
</style>
